import Budget from "./Budget";
import styles from "../css/Overview.module.css"
import PurchaseInput from "./PurchaseInput";
import PurchaseHistory from "./PurchaseHistory";

function Overview({totalBudget, currentBudget, expenseSubmit, dailyAllowance, handleViewChange, logout, recentHistory}) {
  return (
    <>
      <div className={styles.overviewBox}>
        <Budget currentBudget={currentBudget} totalBudget={totalBudget} dailyAllowance={dailyAllowance} />
        <PurchaseHistory history={recentHistory} />
        <PurchaseInput expenseSubmit={expenseSubmit} />
      </div>
      <div className={styles.buttonBox}>
        <button onClick={handleViewChange}>Inställningar</button>
        <button style={{color: "#ff4d4d"}} onClick={logout}>Logga ut</button>
      </div>
    </>
  )
};

export default Overview;