import styles from "../css/PurchaseHistory.module.css"
import dots from "../svg/three-dots.svg"

function HistoryItem({date, amount}) {
  const parsedDate = new Date(date).toISOString().split('T')[0];
  return (
    <div className={styles.historyItem}>
      {parsedDate} - <span style={{fontWeight: "bold", fontStyle: "italic"}}>{amount} kr</span>
    </div>
  )
}

function PurchaseHistory({history}) {
  return (
    <div className={styles.container}>
      Historik
      <div className={styles.historyBox}>
        {history && history.length > 0 ? 
          history.map((item, index) => <HistoryItem key={index} date={item.date} amount={item.amount} />)
          :
          <div className={styles.noHistory}>Ingen historik</div>  
        }
      </div>
      <img src={dots}></img>
    </div>
  )
}

export default PurchaseHistory;