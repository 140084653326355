import { useEffect, useState } from "react";
import Overview from "./components/Overview";
import Settings from "./components/Settings";
import styles from "./css/App.module.css";
import axios from "axios";
import Login from "./components/Login";
import Setup from "./components/Setup";
import Menubar from "./components/Menubar";

const VIEWS = {
  OVERVIEW: 0,
  SETTINGS: 1,
  LOGIN: 2,
  LOADING: 3,
  SETUP: 4,
};

const getLocalISOTime = () => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000;
  const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();
  return localISOTime;
};

function App() {
  const [totalBudget, setTotalBudget] = useState(localStorage.getItem("totalBudget") || 0);
  const [currentBudget, setCurrentBudget] = useState(localStorage.getItem("currentBudget") || 0);
  const [dailyAllowance, setDailyAllowance] = useState(localStorage.getItem("dailyAllowance") || 0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [firstTimeSetup, setFirstTimeSetup] = useState(false);
  const [recentHistory, setRecentHistory] = useState(JSON.parse(localStorage.getItem("recentHistory")) || []);
  const [loginError, setLoginError] = useState('');

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
  });

  const [currentView, setCurrentView] = useState(VIEWS.LOADING);

  const checkLogin = async () => {
    const response = await axiosInstance.get('/auth/whoami');
    if (response.data.username) {
      setFirstTimeSetup(!response.data.firstSetupFinished);
    }
    return response.data.username || false;
  };

  useEffect(() => {
    async function fetchData() {
      const username = await checkLogin();
      if (username) {
        setIsLoggedIn(true);
        setUsername(username);
      } else {
        setIsLoggedIn(false);
        setUsername('');
        setCurrentView(VIEWS.LOGIN);
      }
    }
    fetchData();
  }, []);

  const getCurrentBudget = async () => {
    const response = await Promise.all([
      axiosInstance.post('/budget', {date: getLocalISOTime(),}),
      axiosInstance.get('/budget/expenses/recent'),
    ]);
    const { currentBudget, totalBudget, dailyAllowance } = response[0].data;
    setCurrentBudget(currentBudget);
    setTotalBudget(totalBudget);
    setDailyAllowance(dailyAllowance);
    localStorage.setItem("currentBudget", currentBudget);
    localStorage.setItem("totalBudget", totalBudget);
    localStorage.setItem("dailyAllowance", dailyAllowance);
    setRecentHistory(response[1].data);
    localStorage.setItem("recentHistory", JSON.stringify(response[1].data));
  };

  const handleViewChange = () => {
    if (currentView === VIEWS.OVERVIEW) {
      setCurrentView(VIEWS.SETTINGS);
    } else {
      setCurrentView(VIEWS.OVERVIEW);
    }
  };

  const expenseSubmit = async (amount, isFixed) => {
    const response = await axiosInstance.post('/budget/expenses', {
      amount,
      isFixed,
      date: getLocalISOTime(),
    });
    if (response.status === 200) {
      getCurrentBudget();
    }
  }

  const handleLogin = async (username, password) => {
    try {
      const response = await axiosInstance.post('/auth/login', { username, password });
      if (response.status === 200) {
        setIsLoggedIn(true);
        setUsername(username);
        checkLogin();
      }
    } catch (err) {
      if (err.response.status === 400 || err.response.status === 401 || err.response.status === 404) {
        setLoginError('Invalid username or password');
      } else {
        setLoginError('Something went wrong, please try again');
      }
    }
  };

  const handleRegister = async (username, password) => {
    try {
      const response = await axiosInstance.post('/auth/register', { username, password });
      if (response.status === 200) {
        setIsLoggedIn(true);
        setUsername(username);
        checkLogin();
      }
    } catch (err) {
      if (err.response.status === 409) {
        setLoginError('Username already exists');
      } else {
        setLoginError('Something went wrong, please try again');
      }
    }
  };

  const handleSettingsSubmit = async (payday, salary, fixedExpenses, savings) => {
    await axiosInstance.post('/budget/settings', { payday, salary, fixedExpenses, savings });
    setCurrentView(VIEWS.OVERVIEW);
  };

  const getSettings = async () => {
    const response = await axiosInstance.get('/budget/settings');
    if (response.status === 200) {
      return response.data;
    }
  };

  const logout = async () => {
    const response = await axiosInstance.post('/auth/logout');
    if (response.status === 200) {
      setIsLoggedIn(false);
      setUsername('');
      setCurrentView(VIEWS.LOGIN);
      setFirstTimeSetup(false);
    }
  };

  useEffect(() => {
    if (firstTimeSetup) {
      setCurrentView(VIEWS.SETUP);
    } else if (isLoggedIn) {
      setCurrentView(VIEWS.OVERVIEW);
    }
  }, [isLoggedIn, username, firstTimeSetup]);

  useEffect(() => {
    if (currentView === VIEWS.OVERVIEW) {
      getCurrentBudget();
    }
  }, [currentView]);

  return (
    <div className={styles.app}>
      <Menubar />
      {currentView === VIEWS.OVERVIEW && 
        <Overview 
          totalBudget={totalBudget} 
          currentBudget={currentBudget} 
          expenseSubmit={expenseSubmit} 
          dailyAllowance={dailyAllowance} 
          handleViewChange={handleViewChange}
          logout={logout}
          recentHistory={recentHistory}
        />
      }
      {currentView === VIEWS.SETTINGS && 
        <Settings 
          handleViewChange={handleViewChange} 
          handleSettingsSubmit={handleSettingsSubmit}
          getSettings={getSettings}
        />
      }
      {currentView === VIEWS.LOGIN &&
        <Login handleLogin={handleLogin} handleRegister={handleRegister} loginError={loginError} />
      }
      {currentView === VIEWS.SETUP &&
        <Setup handleSettingsSubmit={handleSettingsSubmit} />
      }
    </div>
  );
}

export default App;
