import styles from "../css/Budget.module.css"

function Budget({currentBudget, totalBudget, dailyAllowance}) {
  const budgetSuffix = ' kr'
  const budgetStyle = currentBudget >= 0 ? styles.positiveBudget : styles.negativeBudget;

  return (
    <div className={styles.budgetBox}>
      <div className={styles.infoText}>Din balans</div>
      <div className={budgetStyle}>{currentBudget + budgetSuffix}</div>
      <div className={styles.infoText}><span style={{fontWeight: "bold", fontStyle: "italic"}}>+{dailyAllowance + budgetSuffix}</span> per dag</div>
      <div className={styles.infoText}>Totalt belopp: <span style={{fontWeight: "bold", fontStyle: "italic"}}>{totalBudget + budgetSuffix}</span></div>
    </div>
  )
}

export default Budget;