import styles from "../css/Menubar.module.css"

function Menubar() {
  return (
    <div className={styles.menubar}>
      <div className={styles.logoText}>
        B U D G I T
      </div>
    </div>
  );
}

export default Menubar;
