import { useState } from "react";
import styles from "../css/PurchaseInput.module.css";

function PurchaseInput({expenseSubmit}) {
  const [inputNumber, setInputNumber] = useState('');
  const [isFixed, setIsFixed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const parsedNumber = parseInt(inputNumber);
    if (!isNaN(parsedNumber)) expenseSubmit(parsedNumber, isFixed);
    setInputNumber('');
  }

  return (
    <form className={styles.purchaseInputForm} onSubmit={handleSubmit}>
      <input type="text" onChange={(e) => setInputNumber(e.target.value)} value={inputNumber} placeholder='Belopp'></input>
      <label><input style={{marginRight: "6px"}} type="checkbox" id='fixed' onChange={() => setIsFixed(!isFixed)} />Fast utgift</label>
      <button className={styles.buttonSubmit} type='submit'>Lägg till</button>
    </form>
  )
};

export default PurchaseInput;
