import styles from "../css/Settings.module.css"
import { useState, useEffect } from "react";
import Menubar from "./Menubar";

function Settings({ handleSettingsSubmit, getSettings }) {
  const [payday, setPayday] = useState(25);
  const [salary, setSalary] = useState(0);
  const [fixedExpenses, setFixedExpenses] = useState(0);
  const [savings, setSavings] = useState(0);
  const [loading, setLoading] = useState(true);

  const loadSettings = async () => {
    setLoading(true);
    const settings = await getSettings();
    setPayday(settings.payday);
    setSalary(settings.salary);
    setFixedExpenses(settings.fixedExpenses);
    setSavings(settings.savings);
    setLoading(false);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const handlePaydayChange = (e) => {
    const parsedPayday = parseInt(e.target.value);
    if (parsedPayday > 0 && parsedPayday < 32) {
      setPayday(parsedPayday);
    }
    if (e.target.value === "") {
      setPayday(0);
    }
  };

  const handleSalaryChange = (e) => {
    const parsedSalary = parseInt(e.target.value);
    if (!isNaN(parsedSalary)) setSalary(parsedSalary);
    if (e.target.value === '') setSalary(0);
  }

  const handleExpensesChange = (e) => {
    const parsedExpenses = parseInt(e.target.value);
    if (!isNaN(parsedExpenses)) setFixedExpenses(parsedExpenses);
    if (e.target.value === '') setFixedExpenses(0);
  }
  
  const handleSavingsChange = (e) => {
    const parsedSavings = parseInt(e.target.value);
    if (!isNaN(parsedSavings)) setSavings(parsedSavings);
    if (e.target.value === '') setSavings(0);
  }

  const handleSubmit = () => {
    handleSettingsSubmit(
      payday,
      salary,
      fixedExpenses,
      savings,
    );
  };

  return (
    <div className={styles.settingsContainer}>
      <h1 style={{textAlign: "center"}}>Inställningar</h1>
      <p className={styles.inputTitle}>Löningsdag</p>
      <input type="text" placeholder="1 to 28" onChange={handlePaydayChange} value={payday || ''} disabled={loading}/>
      <p className={styles.inputTitle}>Månadslön</p>
      <input type="text" onChange={handleSalaryChange} value={salary || ''} disabled={loading}></input>
      <p className={styles.inputTitle}>Utgifter per månad</p>
      <input type="text" onChange={handleExpensesChange} value={fixedExpenses || ''} disabled={loading}></input>
      <p className={styles.inputTitle}>Månadssparande</p>
      <input type="text" onChange={handleSavingsChange} value={savings || ''} disabled={loading}></input>
      <button onClick={handleSubmit} style={{marginTop: "1rem"}}>Klar</button>
    </div>
  )
}

export default Settings;