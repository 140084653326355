import { useState } from 'react';
import styles from '../css/Login.module.css';

const VIEWS = {
  LOGIN: 0,
  REGISTER: 1,
};

function Login({ handleLogin, handleRegister, loginError }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [wrongInput, setWrongInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentView, setCurrentView] = useState(VIEWS.LOGIN);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setWrongInput(false);
    setErrorMessage('');
    if (!username || !password) {
      setWrongInput(true);
      setErrorMessage('Du måste fylla i alla fält.');
    } else {
      const response = await handleLogin(username, password);
      if (response) {
        setWrongInput(true);
        setErrorMessage(response);
      }
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setWrongInput(false);
    setErrorMessage('');
    if (!username || !password || !confirmPassword) {
      setWrongInput(true);
      setErrorMessage('Du måste fylla i alla fält.');
    } else if (password !== confirmPassword) {
      setWrongInput(true);
      setErrorMessage('Lösenorden matchar inte.');
    } else if (!username.match(/^[a-zA-Z0-9_]{3,20}$/)) {
      setWrongInput(true);
      setErrorMessage('Användarnamnet måste vara mellan 3 och 20 tecken långt och kan endast innehålla bokstäver, siffror och understreck.');
    } else if (!password.match(/^[^\s]{8,30}$/)) {
      setWrongInput(true);
      setErrorMessage('Lösenordet måste vara mellan 8 och 30 tecken långt och får inte innehålla mellanslag.');
    } else {
      handleRegister(username, password);
    }
  };

  return (
    <>
      <div className={styles.loginBlock}>
        <h1 className={styles.loginHeader}>Välkommen!</h1>
        {
          currentView === VIEWS.LOGIN ?
            <>
              <form className={styles.loginForm} onSubmit={handleLoginSubmit}>
                <input type="text" placeholder='Användarnamn' value={username} onChange={handleUsernameChange}/>
                <input type="password" placeholder='Lösenord' value={password} onChange={handlePasswordChange}/>
                <div className={styles.buttonBox}>
                  <input className={styles.submitButton} type="submit" value="Logga in"/>
                  <button className={styles.submitButton} onClick={() => setCurrentView(VIEWS.REGISTER)}>Registrera</button>
                </div>
              </form>
            </>
          :
            <>
              <form className={styles.loginForm} onSubmit={handleRegisterSubmit}>
                <input type="text" placeholder='Användarnamn' value={username} onChange={handleUsernameChange}/>
                <input type="password" placeholder='Lösenord' value={password} onChange={handlePasswordChange}/>  
                <input type="password" placeholder='Bekräfta lösenord' value={confirmPassword} onChange={handleConfirmPasswordChange}/>
                <div className={styles.buttonBox}>
                  <input className={styles.submitButton} type="submit" value="Registrera"/>
                  <button className={styles.submitButton} onClick={() => setCurrentView(VIEWS.LOGIN)}>Gå tillbaka</button>
                </div>
              </form>
            </>
        }
        
      </div>
      <div className={styles.errorMessage}>{loginError || errorMessage}</div>
    </>
  );
}

export default Login;