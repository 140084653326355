import { useState } from "react";
import styles from "../css/Setup.module.css"

const stage = {
  WELCOME: 0,
  PAYDAY: 1,
  INCOME: 2,
  EXPENSES: 3,
  SAVINGS: 4,
  DONE: 5,
};

function Setup({ handleSettingsSubmit }) {
  const [currentStage, setCurrentStage] = useState(stage.WELCOME);
  const [payday, setPayday] = useState(25);
  const [income, setIncome] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [savings, setSavings] = useState(0);

  const handlePaydayChange = (e) => {
    setPayday(e.target.value);
  };

  const handleIncomeChange = (e) => {
    setIncome(e.target.value);
  };

  const handleIncomeSubmit = () => {
    if (isNaN(income)) {
      alert("Please enter a number");
      return;
    }
    setIncome(parseFloat(income));
    setCurrentStage(stage.EXPENSES);
  };

  const handleExpensesChange = (e) => {
    setExpenses(e.target.value);
  };

  const handleExpensesSubmit = () => {
    if (isNaN(expenses)) {
      alert("Please enter a number");
      return;
    }
    setExpenses(parseFloat(expenses));
    setCurrentStage(stage.SAVINGS);
  };

  const handleSavingsChange = (e) => {
    setSavings(e.target.value);
  };

  const handleSavingsSubmit = () => {
    if (isNaN(savings)) {
      alert("Please enter a number");
      return;
    }
    setSavings(parseFloat(savings));
    setCurrentStage(stage.DONE);
  };

  const handleSubmit = () => {
    handleSettingsSubmit(payday, income, expenses, savings);
  };

  return (
    <>
      {currentStage === stage.WELCOME && (
        <div className={styles.welcomeBox}>
          <h1>Välkommen till Budgit!</h1>
          <p>Ställ in din profil här.<br/> Värdena behöver inte vara exakta, du kan ändra i inställningar efteråt.</p>
          <button onClick={() => setCurrentStage(stage.PAYDAY)}>Okej!</button>
        </div>
      )}

      {currentStage === stage.PAYDAY && (
        <div className={styles.welcomeBox}>
          <h1>Löningsdag</h1>
          <p>Vilket datum i månaden får du lön?</p>
          <select onChange={handlePaydayChange} name="payday" id="payday" defaultValue={25}>
            {Array.from(Array(28).keys()).map((day) => (
              <option key={day + 1} value={day + 1}>{day + 1}</option>
            ))}
          </select>
          <button onClick={() => setCurrentStage(stage.INCOME)}>Nästa</button>
        </div>
      )}

      {currentStage === stage.INCOME && (
        <div className={styles.welcomeBox}>
          <h1>Månadslön</h1>
          <p>Hur mycket får du i lön? {'('}efter skatt{')'}</p>
          <input type="number" placeholder="Inkomst" onChange={handleIncomeChange} />
          <button onClick={handleIncomeSubmit}>Nästa</button>
        </div>
      )}

      {currentStage === stage.EXPENSES && (
        <div className={styles.welcomeBox}>
          <h1>Fasta utgifter</h1>
          <p>Hyra, abbonnemang, försäkringar etc. Betalningar som inte ändras varje månad.</p>
          <input type="number" placeholder="Utgifter" onChange={handleExpensesChange} />
          <button onClick={handleExpensesSubmit}>Nästa</button>
        </div>
      )}

      {currentStage === stage.SAVINGS && (
        <div className={styles.welcomeBox}>
          <h1>Sparande</h1>
          <p>Hur mycket vill du spara varje månad?</p>
          <input type="number" placeholder="Besparingar" onChange={handleSavingsChange} />
          <button onClick={handleSavingsSubmit}>Nästa</button>
        </div>
      )}

      {currentStage === stage.DONE && (
        <div className={styles.welcomeBox}>
          <h1>Klar!</h1>
          <p>Du kan alltid ändra beloppen i inställningar.</p>
          <button onClick={handleSubmit}>Starta</button>
        </div>
      )}
    </>
    
  )
}

export default Setup;
